
/* styles.css */

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 2s infinite;
}

.barcode {
  left: 50%;
  box-shadow: 1px 0 0 1px, 5px 0 0 1px, 10px 0 0 1px, 11px 0 0 1px, 15px 0 0 1px, 18px 0 0 1px, 22px 0 0 1px, 23px 0 0 1px, 26px 0 0 1px, 30px 0 0 1px, 35px 0 0 1px, 37px 0 0 1px, 41px 0 0 1px, 44px 0 0 1px, 47px 0 0 1px, 51px 0 0 1px, 56px 0 0 1px, 59px 0 0 1px, 64px 0 0 1px, 68px 0 0 1px, 72px 0 0 1px, 74px 0 0 1px, 77px 0 0 1px, 81px 0 0 1px, 85px 0 0 1px, 88px 0 0 1px, 92px 0 0 1px, 95px 0 0 1px, 96px 0 0 1px, 97px 0 0 1px, 101px 0 0 1px, 105px 0 0 1px, 109px 0 0 1px, 110px 0 0 1px, 113px 0 0 1px, 116px 0 0 1px, 120px 0 0 1px, 123px 0 0 1px, 127px 0 0 1px, 130px 0 0 1px, 131px 0 0 1px, 134px 0 0 1px, 135px 0 0 1px, 138px 0 0 1px, 141px 0 0 1px, 144px 0 0 1px, 147px 0 0 1px, 148px 0 0 1px, 151px 0 0 1px, 155px 0 0 1px, 158px 0 0 1px, 162px 0 0 1px, 165px 0 0 1px, 168px 0 0 1px, 173px 0 0 1px, 176px 0 0 1px, 177px 0 0 1px, 180px 0 0 1px;
  display: inline-block;
  transform: translateX(-90px);
}

/* Add this CSS in your global styles or component file */

/* Hide scrollbar for Chrome, Safari, Opera, and Edge */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  scrollbar-width: thin;
}

.scrollbar-hide::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
    background-color: #000; /* Default color */
  }
  50% {
    transform: scale(1.04);  /* Slightly bigger scaling */
    background-color: #22924e; /* Color when 'beating' */
  }
  100% {
    transform: scale(1);
    background-color: #000; /* Back to original color */
  }
}

.animate-heartbeat {
  animation: heartbeat 4s ease-in-out infinite; /* Even slower heartbeat */
}

